<template>
  <div class="tag-list">
    <Tag
      v-for="(tag, index) in tags"
      :key="`tag-${index}`"
      :title="tag.text || tag"
    />
    <slot />
  </div>
</template>

<script lang="ts">
import { ITag } from '~/types/tag'

export default {
  name: 'TagList',

  props: {
    tags: {
      type: Array as PropType<string[] | ITag[]>,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.tag-list {
  display: inline-flex;
  flex-wrap: wrap;
  row-gap: rem(8);

  &:deep(.tooltip) {
    margin-left: rem(4);
  }
}
</style>
